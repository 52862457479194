






















































































































import { Line as LineChart } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from 'chart.js'

import {
  addCommas,
  formatPrice,
  formatDateLabel,
  currencyToSymbol,
} from '@/lib/utils'
import { getElasticSearchClient } from '@/services/elasticSearch'
import { CurrencyEnum } from '../../../../shared/types/types'
import Metric from '../../components/reports/Metric.vue'
import DatePickerMixin from '../../mixins/DatePicker.mixin'
import { IntervalEnum } from '../../lib/reports/types'
import CurrencySelect from '../../components/shared/inputs/CurrencySelect.vue'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)

export default DatePickerMixin.extend({
  name: 'Dashboard',
  components: {
    CurrencySelect,
    LineChart,
    Metric,
  },
  data() {
    return {
      timeInterval: IntervalEnum.Day,
      elasticData: [] as Record<string, any>[],
    }
  },
  computed: {
    chartOptions() {
      return {
        responsive: true,
        minBarLength: 5,
        maintainAspectRatio: false,
      }
    },
    currencySymbol() {
      return currencyToSymbol(CurrencyEnum.Usd)
    },
    moneyChartOptions() {
      return {
        responsive: true,
        minBarLength: 5,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              stepSize: 100,
              callback: (value: any) =>
                formatPrice({
                  amount: value * 100,
                  currency: CurrencyEnum.Usd,
                }),
            },
          },
        },
      }
    },
    defaultQuery(): Record<string, any> {
      return {
        bool: {
          must: [
            {
              bool: {
                minimum_should_match: 1,
                should: [
                  {
                    terms: {
                      paymentStatus: ['paid'],
                    },
                  },
                  {
                    terms: {
                      paymentStatus: ['partially_refunded'],
                    },
                  },
                  {
                    terms: {
                      paymentStatus: ['refunded'],
                    },
                  },
                ],
                must_not: [],
              },
            },
          ],
          filter: [
            {
              range: {
                '@timestamp': {
                  format: 'strict_date_optional_time',
                  gte: this.datePicker.startDate,
                  lte: this.datePicker.endDate,
                },
              },
            },
          ],
        },
      }
    },
    shiftedDefaultQuery(): Record<string, any> {
      const query = JSON.parse(JSON.stringify(this.defaultQuery))
      query.bool.filter[0].range['@timestamp'].lte = this.timeShiftEndDate
      return query
    },
    timeShiftStartDate(): Date {
      const date = new Date(this.datePicker.startDate)
      date.setDate(date.getDate() - 7)
      return date
    },
    timeShiftEndDate(): Date {
      const date = new Date(this.datePicker.endDate)
      date.setDate(date.getDate() - 7)
      return date
    },
    totalOrdersQuery(): Record<string, any> {
      return {
        aggs: {},
        size: 0,
        track_total_hits: true,
        query: this.defaultQuery,
      }
    },
    totalOrders(): string {
      try {
        return addCommas(this.elasticData[0].hits.total.value)
      } catch (e) {
        return '0'
      }
    },
    totalRevenueQuery(): Record<string, any> {
      return {
        aggs: {
          total_revenue: {
            sum: {
              field: 'usdPrices.totalPrice.amount',
            },
          },
          total_fee: {
            sum: {
              field: 'usdPrices.fee.amount',
            },
          },
          total_chargebacks: {
            sum: {
              field: 'usdPrices.totalChargebacks.amount',
            },
          },
          total_chargeback_fee: {
            sum: {
              field: 'usdPrices.totalChargebackFee.amount',
            },
          },
        },
        size: 0,
        track_total_hits: true,
        query: this.defaultQuery,
      }
    },
    totalRevenue(): string {
      try {
        return formatPrice({
          amount: this.elasticData[1].aggregations.total_revenue.value,
          currency: CurrencyEnum.Usd,
        })
      } catch (e) {
        return formatPrice({
          amount: 0,
          currency: CurrencyEnum.Usd,
        })
      }
    },
    avgOrderValueQuery(): Record<string, any> {
      return {
        aggs: {
          average_order_value: {
            avg: {
              field: 'usdPrices.totalPrice.amount',
            },
          },
        },
        size: 0,
        track_total_hits: true,
        query: this.defaultQuery,
      }
    },
    avgOrderValue(): string {
      try {
        return formatPrice({
          amount: this.elasticData[2].aggregations.average_order_value.value,
          currency: CurrencyEnum.Usd,
        })
      } catch (e) {
        return formatPrice({
          amount: 0,
          currency: CurrencyEnum.Usd,
        })
      }
    },
    totalOrderFeeValue(): string {
      try {
        return formatPrice({
          amount: this.elasticData[1].aggregations.total_fee.value,
          currency: CurrencyEnum.Usd,
        })
      } catch (e) {
        return formatPrice({
          amount: 0,
          currency: CurrencyEnum.Usd,
        })
      }
    },
    totalChargebackValue(): string {
      try {
        return formatPrice({
          amount: -this.elasticData[1].aggregations.total_chargebacks.value,
          currency: CurrencyEnum.Usd,
        })
      } catch (e) {
        return formatPrice({
          amount: 0,
          currency: CurrencyEnum.Usd,
        })
      }
    },
    totalChargebackFeeValue(): string {
      try {
        return formatPrice({
          amount: this.elasticData[1].aggregations.total_chargeback_fee.value,
          currency: CurrencyEnum.Usd,
        })
      } catch (e) {
        return formatPrice({
          amount: 0,
          currency: CurrencyEnum.Usd,
        })
      }
    },
    ordersOverTimePeriodQuery(): Record<string, any> {
      return {
        aggs: {
          ordersOverTimePeriod: {
            date_histogram: {
              field: '@timestamp',
              calendar_interval: this.timeInterval,
              offset: '+4h',
              extended_bounds: {
                min: this.datePicker.startDate.getTime(),
                max: this.datePicker.endDate.getTime(),
              },
            },
          },
        },
        size: 0,
        query: this.defaultQuery,
      }
    },
    ordersOverTimePeriod(): {
      labels: string[]
      datasets: {
        label: string
        data: number[]
        borderColor: string
        backgroundColor: string
        fill: boolean
      }[]
    } {
      try {
        const {
          buckets,
        } = this.elasticData[3].aggregations.ordersOverTimePeriod
        const labels = buckets.map(
          (bucket: Record<string, any>) => bucket.key_as_string
        )

        const datasets = [
          {
            label: 'Orders',
            data: buckets.map(
              (bucket: Record<string, any>) => bucket.doc_count
            ),
            borderColor: '#FF6384',
            backgroundColor: '#FF6384',
            fill: false,
          },
        ]
        return {
          labels: labels.map((label: string) =>
            formatDateLabel(
              labels.map((l: string) => new Date(l)),
              new Date(label),
              this.timeInterval
            )
          ),
          datasets,
        }
      } catch (e) {
        return {
          labels: [],
          datasets: [],
        }
      }
    },
    ordersDifferenceToLastWeekQuery(): Record<string, any> {
      return {
        aggs: {
          orders: {
            date_histogram: {
              field: '@timestamp',
              calendar_interval: this.timeInterval,
              offset: '+4h',
            },
          },
        },
        size: 0,
        query: this.shiftedDefaultQuery,
      }
    },
    ordersDifferenceToLastWeek(): {
      labels: string[]
      datasets: {
        label: string
        data: number[]
        borderColor: string
        backgroundColor: string
        fill: boolean
      }[]
    } {
      if (!this.elasticData[4]) {
        return {
          labels: [],
          datasets: [],
        }
      }

      try {
        const { buckets } = this.elasticData[4].aggregations.orders
        const labels = buckets.map(
          (bucket: Record<string, any>) => bucket.key_as_string
        )
        const datasets = [
          {
            label: 'Orders',
            data: buckets
              .filter((_: any, index: number) => index > 0)
              .map((bucket: Record<string, any>, index: number) => {
                const beforeBucket = buckets[index]
                return beforeBucket.doc_count - bucket.doc_count
              }),
            borderColor: '#FF6384',
            backgroundColor: '#FF6384',
            fill: false,
          },
        ]
        return {
          labels: labels.map((label: string) =>
            formatDateLabel(
              labels.map((l: string) => new Date(l)),
              new Date(label),
              this.timeInterval
            )
          ),
          datasets,
        }
      } catch (e) {
        console.error(e)
        return {
          labels: [],
          datasets: [],
        }
      }
    },
    revenueOverTimePeriodQuery(): Record<string, any> {
      return {
        aggs: {
          revenue: {
            date_histogram: {
              field: '@timestamp',
              calendar_interval: this.timeInterval,
              offset: '+4h',
            },
            aggs: {
              totalPrice: {
                sum: {
                  field: 'usdPrices.totalPrice.amount',
                },
              },
            },
          },
        },
        size: 0,
        query: this.defaultQuery,
      }
    },
    revenueOverTimePeriod(): {
      labels: string[]
      datasets: {
        label: string
        data: number[]
        borderColor: string
        backgroundColor: string
        fill: boolean
      }[]
    } {
      try {
        const { buckets } = this.elasticData[5].aggregations.revenue
        const labels = buckets.map(
          (bucket: Record<string, any>) => bucket.key_as_string
        )

        const datasets = [
          {
            label: '$ Revenue',
            data: buckets.map(
              (bucket: Record<string, any>) => bucket.totalPrice.value / 100
            ),
            borderColor: '#FF6384',
            backgroundColor: '#FF6384',
            fill: false,
          },
        ]
        return {
          labels: labels.map((label: string) =>
            formatDateLabel(
              labels.map((l: string) => new Date(l)),
              new Date(label),
              this.timeInterval
            )
          ),
          datasets,
        }
      } catch (e) {
        return {
          labels: [],
          datasets: [],
        }
      }
    },
    averageOrderValueOverTimeQuery(): Record<string, any> {
      return {
        aggs: {
          average_histogram: {
            date_histogram: {
              field: '@timestamp',
              calendar_interval: this.timeInterval,
              offset: '+4h',
            },
            aggs: {
              average: {
                avg: {
                  field: 'usdPrices.totalPrice.amount',
                },
              },
            },
          },
        },
        size: 0,
        query: this.defaultQuery,
      }
    },
    averageOrderValueOverTime(): {
      labels: string[]
      datasets: {
        label: string
        data: number[]
        borderColor: string
        backgroundColor: string
        fill: boolean
      }[]
    } {
      try {
        const { buckets } = this.elasticData[6].aggregations.average_histogram
        const labels = buckets.map(
          (bucket: Record<string, any>) => bucket.key_as_string
        )

        const datasets = [
          {
            label: 'Avg Revenue',
            data: buckets.map(
              (bucket: Record<string, any>) => bucket.average.value / 100
            ),
            borderColor: '#FF6384',
            backgroundColor: '#FF6384',
            fill: false,
          },
        ]
        return {
          labels: labels.map((label: string) =>
            formatDateLabel(
              labels.map((l: string) => new Date(l)),
              new Date(label),
              this.timeInterval
            )
          ),
          datasets,
        }
      } catch (e) {
        return {
          labels: [],
          datasets: [],
        }
      }
    },
    averageOrderValueDifferenceInPercentQuery(): Record<string, any> {
      return {
        aggs: {
          average_histogram: {
            date_histogram: {
              field: '@timestamp',
              calendar_interval: this.timeInterval,
              offset: '+4h',
            },
            aggs: {
              average: {
                avg: {
                  field: 'usdPrices.totalPrice.amount',
                },
              },
            },
          },
        },
        size: 0,
        query: this.shiftedDefaultQuery,
      }
    },
    averageOrderValueDifferenceInPercent(): {
      labels: string[]
      datasets: {
        label: string
        data: number[]
        borderColor: string
        backgroundColor: string
        fill: boolean
      }[]
    } {
      if (!this.elasticData[7]) {
        return {
          labels: [],
          datasets: [],
        }
      }

      try {
        const { buckets } = this.elasticData[7].aggregations.average_histogram
        const labels = buckets.map(
          (bucket: Record<string, any>) => bucket.key_as_string
        )
        const datasets = [
          {
            label: 'Average Order Value Difference In Percent',
            data: buckets
              .filter((_: any, index: number) => index > 0)
              .map((bucket: Record<string, any>, index: number) => {
                const beforeBucket = buckets[index]
                const currentValue = bucket.average.value || 0
                const beforeValue = beforeBucket.average.value || 0

                return ((currentValue - beforeValue) / beforeValue) * 100
              }),
            borderColor: '#FF6384',
            backgroundColor: '#FF6384',
            fill: false,
          },
        ]
        return {
          labels: labels.map((label: string) =>
            formatDateLabel(
              labels.map((l: string) => new Date(l)),
              new Date(label),
              this.timeInterval
            )
          ),
          datasets,
        }
      } catch (e) {
        console.error(e)
        return {
          labels: [],
          datasets: [],
        }
      }
    },
    averageOrderValueDifferenceToLastWeekQuery(): Record<string, any> {
      return {
        aggs: {
          sum_histogram: {
            date_histogram: {
              field: '@timestamp',
              calendar_interval: this.timeInterval,
              offset: '+4h',
            },
            aggs: {
              sum: {
                sum: {
                  field: 'usdPrices.totalPrice.amount',
                },
              },
            },
          },
        },
        size: 0,
        query: this.shiftedDefaultQuery,
      }
    },
    averageOrderValueDifferenceToLastWeek(): {
      labels: string[]
      datasets: {
        label: string
        data: number[]
        borderColor: string
        backgroundColor: string
        fill: boolean
      }[]
    } {
      if (!this.elasticData[8]) {
        return {
          labels: [],
          datasets: [],
        }
      }

      try {
        const { buckets } = this.elasticData[8].aggregations.sum_histogram
        const labels = buckets.map(
          (bucket: Record<string, any>) => bucket.key_as_string
        )
        const datasets = [
          {
            label: 'Average Order Value Difference To Last Week',
            data: buckets
              .filter((_: any, index: number) => index > 0)
              .map((bucket: Record<string, any>, index: number) => {
                const beforeBucket = buckets[index]
                const currentValue = bucket.sum.value || 0
                const beforeValue = beforeBucket.sum.value || 0
                return (beforeValue - currentValue) / 100
              }),
            borderColor: '#FF6384',
            backgroundColor: '#FF6384',
            fill: false,
          },
        ]
        return {
          labels: labels.map((label: string) =>
            formatDateLabel(
              labels.map((l: string) => new Date(l)),
              new Date(label),
              this.timeInterval
            )
          ),
          datasets,
        }
      } catch (e) {
        console.error(e)
        return {
          labels: [],
          datasets: [],
        }
      }
    },
    topProductsQuery(): Record<string, any> {
      const query = JSON.parse(JSON.stringify(this.defaultQuery))
      // const query = {
      //   match_all: {},
      // }

      // console.log(
      //   '🚀 ~ file: Dashboard.vue:770 ~ topProductsQuery ~ query',
      //   query
      // )
      // delete query.bool.must
      // console.log(
      //   '🚀 ~ file: Dashboard.vue:803 ~ topProductsQuery ~ query',
      //   query
      // )

      // Todo: find a way how to bring back this filter
      // they might have to be indexed along with the line items
      delete query.bool.must

      return {
        aggs: {
          top_products: {
            terms: {
              field: 'productTitle.keyword',
              order: {
                quantity_sum: 'desc',
              },
              size: 5,
            },
            aggs: {
              quantity_sum: {
                sum: {
                  field: 'quantity',
                },
              },
              total_price_sum: {
                sum: {
                  field: 'usdPrices.totalPrice.amount',
                },
              },
            },
          },
        },
        size: 0,
        query,
      }
    },
    productsTableData(): {
      product: string
      quantity: string
      revenue: string
    }[] {
      if (!this.elasticData[9]) {
        return []
      }
      return this.elasticData[9]?.aggregations?.top_products.buckets.map(
        (bucket: {
          key: string
          quantity_sum: { value: number }
          total_price_sum: { value: number }
        }) => {
          return {
            product: bucket.key,
            quantity: addCommas(bucket.quantity_sum.value),
            revenue: formatPrice({
              amount: bucket.total_price_sum.value,
              currency: CurrencyEnum.Usd,
            }),
          }
        }
      )
    },
    productColumns(): { field: string; label: string; numeric?: true }[] {
      return [
        {
          field: 'product',
          label: 'Product',
        },
        {
          field: 'quantity',
          label: 'Quantity',
          numeric: true,
        },
        {
          field: 'revenue',
          label: '$ Revenue',
          numeric: true,
        },
      ]
    },
  },
  watch: {
    currency: {
      handler(): void {
        this.fetchData()
      },
    },
  },
  methods: {
    onRangeChanged({
      startDate,
      endDate,
    }: {
      startDate: Date
      endDate: Date
    }): Promise<void> {
      this.datePicker.startDate = startDate
      this.datePicker.endDate = endDate
      return this.fetchData()
    },
    async fetchData(): Promise<void> {
      const elasticSearch = getElasticSearchClient()
      const elasticData = await Promise.all([
        elasticSearch.post('/orders/_search', this.totalOrdersQuery),
        elasticSearch.post('/orders/_search', this.totalRevenueQuery),
        elasticSearch.post('/orders/_search', this.avgOrderValueQuery),
        elasticSearch.post('/orders/_search', this.ordersOverTimePeriodQuery),
        elasticSearch.post(
          '/orders/_search',
          this.ordersDifferenceToLastWeekQuery
        ),
        elasticSearch.post('/orders/_search', this.revenueOverTimePeriodQuery),
        elasticSearch.post(
          '/orders/_search',
          this.averageOrderValueOverTimeQuery
        ),
        elasticSearch.post(
          '/orders/_search',
          this.averageOrderValueDifferenceInPercentQuery
        ),
        elasticSearch.post(
          '/orders/_search',
          this.averageOrderValueDifferenceToLastWeekQuery
        ),
        elasticSearch.post('/order_line_items/_search', this.topProductsQuery),
      ])
      this.elasticData = elasticData.map((d) => d.data)
    },
  },
  created(): void {
    this.fetchData()
  },
})
